<template>
    <div class="pagepur">
        <!-- 传过来的标题 -->
        <titlebar :name="name"/>
        <!-- 订单号 -->
        <div class="page_box">订单号：<span>{{ order_num }}</span></div>
        <div class="list_box">
            <div class="page_list">
                <div class="list_num"></div>
                <div class="list_name">商品名称</div>
                <div class="tit1_gy">总数量</div>
                <div class="tit2_gy">单价（元）</div>
                <div class="tit3_gy">小计（元）</div>
                <div class="tit4_gy">退货数量</div>
                <div class="tit5_gy">退货小计</div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="list_box" style="height: calc(100% - 38.8rem);">
            <div
                class="page_list"
                v-for="(item, index) in orderInfolist"
                :key="index"
            >
                <div class="list_num">{{ index + 1 }}</div>
                <div class="list_name">{{ item.name + '(单位：' + item.dw +')' }}</div>
                <div class="tit1_gy">{{ item.can_return_num }}</div>
                <div class="tit2_gy">{{ item.price }}</div>
                <div class="tit3_gy">{{ $bigN(item.can_return_num).times(item.price).toFixed(2) }}</div>
                <div class="tit4_gy">
                    <van-stepper
                        v-model="item.returnsNumberOf"
                        theme="round"
                        button-size="22"
                        disable-input
                        :step="(item.dw == 'kg' || item.dw == '千克' || item.dw == '克' || item.dw == '斤') ? item.num*1 : 1"
                        min="0"
                        :max="item.can_return_num"
                        @change="add(item)"
                    />
                </div>
                <div class="tit5_gy">{{ item.returnsPrcOf }}</div>
            </div>
        </div>
        <!-- 确定退货 -->
        <div class="btm_btn_total">
            <div class="flex flex-ac">
                <div class="text-left mg-rt100">
                    <div class="mg-bt10">总付款：<span>￥{{ pay_total_price }}</span></div>
                    <div>共退货<span>{{ Allnum }}</span>件</div>
                </div>
                <div class="text-left">
                    <div v-if="deduction_discount > 0" class="mg-bt10">积分抵扣：<span>￥{{ deduction_discount }}（{{ use_integral }}积分）</span></div>
                    <div>退款总额:<span>￥{{ return_price }}</span></div>
                </div>
            </div>
            <!--<div class="btn" @click="Delete()">退款</div>-->
            <div class="btn" @click="delClick">退款</div>
        </div>

        <van-popup v-model="showdet" @close="dialogClose">
            <div class="popups">
                <div class="system_title">
                    <div>
                        <span>请输入金额</span>
                    </div>
                    <img src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png" @click="showdet = false;" />
                </div>
                <div class="detail">
                    <div style="display: flex;justify-content: space-between;align-items: center" class="mg-bt30">
                        金额：<input type="number" v-model="return_price" ref="modalInput" autofocus class="" readonly />
                    </div>
                    <div style="display: flex;justify-content: space-between;align-items: center">
                        积分：<input type="number" v-model="return_integral" ref="modalInput" autofocus class="" readonly />
                    </div>
                </div>
                <div class="but" @click="Delete">确定</div>
            </div>
        </van-popup>

        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="payTypeList"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import {Toast} from "vant";
import {accMul, accAdd, subTr} from "../../utils/floatComputer";

export default {
    created() {
        this.name = this.$route.query.name; //拿到传过来的标题
        this.order_num = this.$route.query.curOrderNum; //拿到传过来的订单号
        console.log("name:", this.name);
        this.getorderInfo();
    },
    components: {
        titlebar: titlebar,
    },
    data() {
        return {
            returnsNumberOf: 0, //单品退货数量
            returnsPrcOf: 0, //单品退货的价格
            orderInfolist: [], //退货商品表
            Allnum: 0, //总退货数量
            Allprc: 0, //总退货金额
            checked: true, // 是否打印
            showdet: false, // 退款弹框
            money: '', // 退款金额
            refund_type: '', // 退款方式
            showPicker: false, //'付款方式的弹框'
            payTypeList: [
                { id: 7, text: "收呗" },
                { id: 3, text: "现金" },
                { id: 6, text: "会员余额" },
                { id: 1, text: "微信" },
                { id: 2, text: "支付宝" },
                { id: 4, text: "线上" },
                { id: 5, text: "其他" }
            ], // 付款方式的列表
            payTypeName: '', // 退款方式名
            paymentCode: '', // 退款码
            pay_total_price: 0, // 支付时的总金额
            deduction_discount: 0, // 支付时的积分抵扣金额
            use_integral: 0, // 支付时使用的积分
            return_integral: 0, // 需要退回的积分数量
            change_price: 0, // 订单原价
            return_price: 0, // 退款金额
            residual_amount: 0, // 订单剩余金额
            residual_integral: 0, // 订单剩余积分
        };
    },
    computed: {
        AllreturnsPrcOf() {
        },
        // 小计总和
        all_fact_price() {
            let price = 0;
            for (let i = 0; i < this.orderInfolist.length; i++) {
                const element = this.orderInfolist[i];
                price += element.total_price * 100;
            }
            price = (price / 100).toFixed(2);
            console.log('小计总和', price);
            return price;
        },
        return_price_all() {
            let price = 0;
            for (let i = 0; i < this.orderInfolist.length; i++) {
                const element = this.orderInfolist[i];
                // console.log(element, '------');
                let ele_price = 0;
                if ((element.dw == '克' || element.dw == '千克' || element.dw == '斤') && element.returnsNumberOf > 0) {
                    ele_price = element.total_price * 1;
                } else {
                    ele_price = (element.fact_price * 100) * element.returnsNumberOf / 100;
                }
                // console.log('ele_price', ele_price);
                price = price + ele_price;
            }
            price = price.toFixed(2);
            return price;
        },
    },
    methods: {
        getReturnIntegral() {
            if (this.residual_amount <= 0 || this.all_fact_price <= 0) {
                this.return_integral = 0;
                return;
            }
            let nums = 0;
            for (let i = 0; i < this.orderInfolist.length; i++) {
                const element = this.orderInfolist[i];
                // console.log(element, '------');
                let ele_num = 0;
                // 小计 * 退货数量 / 商品总原价 * 总使用积分
                ele_num = ((element.fact_price * 100) * element.returnsNumberOf / (this.all_fact_price * 100)) * (this.residual_integral * 100) / 100;
                // console.log('ele_num', ele_num);
                nums = nums + ele_num;
            }
            nums = nums.toFixed(2);
            this.return_integral = nums;
        },
        getReturnPrice() {
            if (this.residual_amount <= 0 || this.all_fact_price <= 0) {
                this.return_price = 0;
                return;
            }
            let price = 0;
            for (let i = 0; i < this.orderInfolist.length; i++) {
                const element = this.orderInfolist[i];
                // console.log(element, '------');
                let ele_price = 0;
                // 小计 * 退货数量 / 商品总原价 * 实际付款金额
                if ((element.dw == '克' || element.dw == '千克' || element.dw == '斤') && element.returnsNumberOf > 0) {
                    ele_price = (element.total_price * 100) / (this.all_fact_price * 100) * (this.residual_amount * 100) / 100;
                } else if (element.returnsNumberOf == element.can_return_num) {
                    ele_price = ((element.total_price * 100) / (this.all_fact_price * 100)) * (this.residual_amount * 100) / 100;
                } else {
                    ele_price = ((element.fact_price * 100) * element.returnsNumberOf / (this.all_fact_price * 100)) * (this.residual_amount * 100) / 100;
                }
                // console.log('ele_price', ele_price);
                price = price + ele_price;
            }
            price = price.toFixed(2);
            this.return_price = price;
        },
        confirmDet() {

        },
        // 确认付款方式
        onConfirm(e) {
            this.refund_type = e.id;
            this.payTypeName = e.text;
            this.showdet = true;
            this.showPicker = false;
        },
        //
        delClick() {
            let flag = true;
            let totalNum = 0;
            this.orderInfolist.map(item => {
                totalNum += item.returnsNumberOf
                if (item.returnsNumberOf > item.num) {
                    flag = false;
                }
            })
            console.log(totalNum);
            if (flag && totalNum) {
                this.money = this.Allprc;
                // this.showPicker = true;
                this.showdet = true;
                this.$nextTick(() =>{
                    this.$refs.modalInput.focus();
                },100);
            } else if (!flag) {
                Toast('退货数量大于购买数量！')
            } else if (!totalNum) {
                Toast('请选择退货数量！')
            }

        },
        // 弹框关闭
        dialogClose (){
            this.money = '';
            this.paymentCode = '';
        },
        // 获取退货订单详情
        getorderInfo(type) {
            this.orderInfolist = [];
            let data = {
                shop_id: localStorage.getItem("shop_id"),
                o_type: 1, // 1.已销售订单 2.已售后订单
                order_num: this.order_num,
            };
            this.$api.orderInfo(data).then((res) => {
                console.log(res.data, "获取列表信息");
                this.$toast.clear();
                if (res.code == 1) {
                    this.refund_type = res.data.pay_type;
                    this.pay_total_price = res.data.total_price;
                    let arr = [];
                    res.data.info.forEach(element => {
                        const can_return_num = this.$bigN(element.num).minus(element.return_count).toNumber();
                        let total_price = 0;
                        if (element.dw == '克' || element.dw == '千克' || element.dw == '斤') {
                            total_price = element.total_price;
                        } else {
                            total_price = this.$bigN(element.total_price).div(element.num).times(can_return_num).toNumber().toFixed(2);
                        }
                        if (can_return_num > 0) {
                            arr.push(Object.assign({}, element, {
                                can_return_num: can_return_num,
                                returnsNumberOf: 0,
                                returnsPrcOf: 0,
                                total_price: total_price,
                            }))
                        }
                    });
                    // console.log(arr, 'arr---');
                    this.orderInfolist = arr;
                    // this.orderInfolist.forEach((e) => {
                    //     e.returnsNumberOf = 0;
                    //     e.returnsPrcOf = 0;
                    // });
                    this.deduction_discount = res.data.score_discount || 0;
                    this.use_integral = res.data.use_score || 0;
                    this.change_price = res.data.change_price;
                    this.residual_amount = this.$bigN(this.pay_total_price).minus(res.data.return_money).toNumber().toFixed(2);
                    console.log('residual_amount', this.residual_amount);
                    this.residual_integral = this.$bigN(this.use_integral).minus(res.data.return_score).toNumber().toFixed(2);
                    // 订单全部退款成功后跳转至退款订单页
                    if (type == 1 && this.orderInfolist.length === 0) {
                        setTimeout(() => {
                            this.$router.replace({
                                path: '/orderManage',
                                query: {name: 'orderManage'}
                            });
                        }, 1000);
                    }
                }
            }).catch(() => {
                this.$toast.clear();
            });
        },
        // 退款
        Delete() {
            let r_data = [];
            this.orderInfolist.forEach((element) => {
                if (element.returnsNumberOf > 0) {
                    r_data.push({
                        id: element.id,
                        g_id: element.g_id,
                        number: element.returnsNumberOf
                    });
                }
            });
            if (r_data.length === 0) {
                Toast('请确认退款商品数量');
                return
            }
            ;
            let query = {
                // shop_id: localStorage.getItem("shop_id"),
                r_data: JSON.stringify(r_data), // 退货数据
                order_num: this.order_num,
                // refund_price: this.money, // 退款金额
                refund_price: this.return_price,
                // refund_price: this.return_price_all,
                refund_type: this.refund_type, // 退款方式
                // pay_num: this.paymentCode // 退款码
                score: this.return_integral,
            };
            const Load = Toast.loading({
                message: '退款中...',
                forbidClick: true,
                duration: 0 //设定持续时间为永久,遇见另一个 Toast 或者使用 clear 方法清除
            });
            this.$api.Order_refundOrder(query, 'dontLoad').then((res) => {
                if (res.code == 1) {
                    Toast.success('退款成功');
                    this.showdet = false;
                    this.paymentCode = '';
                    this.Allnum = 0;
                    this.return_price = 0;
                    this.return_integral = 0;
                    this.$toast.loading({
                        message: '刷新中...',
                        forbidClick: true,
                        duration: 0,
                    })
                    this.getorderInfo(1);
                    // 语音播报
                    if (res.data.list.audio_url) {
                        const instance = new Audio(); // 创建音频对象
                        instance.src = res.data.list.audio_url; // 设置音频播放地址
                        instance.play(); // 调用音频播放
                    }
                }
                setTimeout(() => {
                    Load.clear();
                }, 2000);
                console.log(res, "订单退款")
            }, () => {
                setTimeout(() => {
                    Load.clear();
                }, 2000);
            });
        },
        // 增加退货数量
        add(item) {
            console.log("returnsPrcOf", item);
            // item.returnsPrcOf = (item.returnsNumberOf * (item.price * 100)) / 100;
            // item.returnsPrcOf = accMul(item.returnsNumberOf, item.price)
            if (item.dw == '克' || item.dw == '千克' || item.dw == '斤') {
                item.returnsPrcOf = item.returnsNumberOf > 0 ? item.total_price : 0;
            } else if (item.returnsNumberOf == item.can_return_num) {
                item.returnsPrcOf = item.total_price;
            } else {
                item.returnsPrcOf = this.$bigN(item.returnsNumberOf).times(item.fact_price).toNumber().toFixed(2);
            }
            console.log("退货小计：",item.returnsPrcOf);
            this.Allnum = this.orderInfolist.reduce(
                (returnsNumberOf, cur) => {
                    if (cur.dw == 'kg' || cur.dw == '千克' || cur.dw == '克' || cur.dw == '斤') {
                        return returnsNumberOf + 1;
                    } else {
                        // return returnsNumberOf + cur.returnsNumberOf*1
                        return accAdd(returnsNumberOf*1, cur.returnsNumberOf*1);
                    }

                },

                0
            );
            this.Allprc = this.orderInfolist.reduce(
                (returnsPrcOf, cur) => accAdd(returnsPrcOf, cur.returnsPrcOf),
                0
            );
            this.getReturnPrice();
            this.getReturnIntegral();
            this.$forceUpdate();
        },
    },
};
</script>

<style lang="less" scoped>
.tit4_gy {
    input{
        border: 0.2rem solid #f4f4f4;
        text-align: center;
    }
}

.pagepur {
    width: 100%;
    height: 100%;
    background: #f8f8f8;

    .page_box {
        display: flex;
        align-items: center;
        height: 8.1rem;
        padding-left: 7.4rem;
        font-size: 2.2rem;
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        background: #ffffff;
        border-top: 1px solid #e5e5e5;

        span {
            margin-left: 2.4rem;
            font-size: 2.4rem;
            font-weight: 500;
            color: #333333;
            line-height: 3.3rem;
        }
    }

    .page_title {
        width: 100%;
        height: 7.7rem;
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        font-weight: 400;
        color: #333333;
        line-height: 3.3rem;

        .tit_name {
            width: 80rem;
            text-align: left;
            padding-left: 12.5rem;
        }
    }

    .list_box {
        // display: flex;
        // flex-wrap: wrap;
        // flex-direction: row;
        // height: 94.9rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .page_list {
        display: flex;
        width: 100%;
        height: 9.4rem;
        align-items: center;
        background: #ffffff;
        font-size: 2.6rem;
        font-weight: 400;
        color: #333333;
        line-height: 3.7rem;
        border-bottom: 0.15rem solid #e5e5e5;

        .list_num {
            width: 15rem;
        }

        .list_name {
            // width: 65rem;
            width: 30%;
            text-align: left;
        }
    }

    .btm_btn_total {
        width: calc(100vw - 13.5rem);
        height: 12.7rem;
        position: fixed;
        bottom: 0rem;
        background: #ffffff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 0.1rem solid #e5e5e5;
        font-size: 2.6rem;
        font-weight: 400;
        color: #333333;
        line-height: 3.7rem;

        .btm-check {
            margin-right: auto;
            margin-left: 14rem;
            font-size: 3rem;
            font-weight: 500;
            height: 3rem;

            /deep/ .van-checkbox__icon--checked .van-icon {
                background-color: #fe5f00;
                border-color: #fe5f00;
            }

            /deep/ .van-checkbox__label {
                line-height: 3.7rem;
            }

            /deep/ .van-checkbox__icon .van-icon {
                height: 2.7rem;
                width: 2.7rem;
                line-height: 2.7rem;
                margin-top: -0.4rem;
            }
        }

        span {
            color: #1588F5;
            margin: 1rem;
        }

        .btn {
            width: 19.4rem;
            height: 6rem;
            background: #1588F5;
            margin: 0 6.5rem 0 5rem;
            font-size: 2.6rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 3.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .tit1_gy {
        // width: 16rem;
        flex: 1;
    }

    .tit2_gy {
        // width: 20rem;
        flex: 1;
    }

    .tit3_gy {
        width: 20rem;
        // flex: 1;
    }

    .tit4_gy {
        // width: 28rem;
        flex: 1;
        color: #1588F5;

        /deep/ .van-stepper--round .van-stepper__minus {
            width: 2.9rem !important;
            height: 2.9rem !important;
            color: #272536;
            background-color: #fff;
            border: 1px solid #272536;
        }

        /deep/ .van-stepper--round .van-stepper__plus {
            width: 2.9rem !important;
            height: 2.9rem !important;
            background-color: #999999;
        }

        /deep/ .van-stepper__input {
            width: 8rem;
            font-size: 2.8rem;
            font-weight: 400;
            color: #1588F5;
            line-height: 4rem;
            background: #ffffff;
        }
    }

    .tit5_gy {
        width: 28rem;
        color: #1588F5;
    }
}

.popups {
    width: 102.9rem;
    height: 52.7rem;
    background: #ffffff;
    text-align: -webkit-center;

    .system_title {
        display: flex;
        align-items: center;
        //justify-content: flex-end;
        //margin: 0 4.6rem 3.1rem 4.6rem;
        height: 12.5rem;
        border-bottom: 0.1rem solid #e5e5e5;
        font-size: 3.6rem;
        font-weight: 400;
        color: #333333;
        position: relative;
        >:first-child {
            flex: 1;
        }
        img {
            width: 4.3rem;
            position: absolute;
            right: 4rem;
            // height: 4.3rem;
            // text-align: end;
            //margin-left: 29.4rem;
        }
    }

    .detail {
        //margin: 10rem 17.6rem 8.5rem 18rem;
        margin: 6rem 17.6rem 6rem 18rem;
        text-align: left;
        font-size: 2.8rem;
        font-weight: bold;
        color: #333333;
        line-height: 4rem;

        input {
            //width: 62.6rem;
            width: 46rem;
            height: 7.1rem;
            background: #f8f8f8;
            border-radius: 0.8rem;
            //margin: 0 0 2rem 2rem;
            padding-left: 6.3rem;
            font-size: 2.6rem;
            font-weight: 400;
            color: #666666;
            line-height: 3.7rem;
        }

        .detail_context {
            font-size: 3.6rem;
            color: #fe5600;
        }
    }

    .but {
        width: 27rem;
        height: 7rem;
        background: linear-gradient(270deg, #1588F5 0%, #1588F5 100%);
        font-size: 2.8rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/deep/.van-picker__cancel, /deep/.van-picker__confirm, /deep/.van-picker-column {
    font-size: 2.4rem;
}
</style>
